<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort">
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th></vs-th>
        <vs-th sort-key=""></vs-th>
        <vs-th sort-key="">BED ID</vs-th>
        <vs-th sort-key="">Warehouse Code</vs-th>
        <vs-th sort-key="">Counting Date</vs-th>
        <vs-th sort-key=""># SKU</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-input-group flex">
              <vs-button size="small" color="primary" icon-pack="feather" icon="icon-edit"
                         v-if="status === 0 && hasPermission('edit')"
                         title="Edit" style="margin-right: 5px" @click="handleEdit(tr.id)"/>
              <vs-button size="small" color="success" icon-pack="feather" icon="icon-check"
                         v-if="status === 0 && hasPermission('edit')"
                         title="Execute" style="margin-right: 5px" @click="handleExecute(tr.id)"/>
              <vs-button size="small" color="danger" icon-pack="feather" icon="icon-x"
                         v-if="status === 0 && hasPermission('cancel')"
                         title="Cancel" style="margin-right: 5px" @click="handleCancel(tr.id)"/>
              <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye"
                         v-if="status === 2 || status === 1 && hasPermission('view')"
                         title="Detail" style="margin-right: 5px" @click="handleDetail(tr.id)"/>
            </div>
          </vs-td>
          <vs-td></vs-td>
          <vs-td>{{ tr.code }}</vs-td>
          <vs-td>{{ tr.warehouse_code }}</vs-td>
          <vs-td>{{ formatDate(tr.counting_date) }}</vs-td>
          <vs-td>{{ tr.item_count }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage"/>
  </div>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  props: {
    draw: {
      type: Number,
    },
    countingDate: {
      type: Date,
      default: null,
    },
    status: {
      type: Number,
      default: null,
    },
    selectedWarehouse: {
      type: Object,
      default: null,
    },
    isApproval: {
      type: Number,
      default: null,
    },
    remark: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      deleteId: null,
      action: null,
      table: this.tableDefaultState(),
      checkedAll: false,
      checked: [],
      selectedRouteAssignmentToAssign: [],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/batch-ed/get-all-document", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            status: this.status,
            warehouse_id: this.selectedWarehouse ? this.selectedWarehouse.id : null,
            counting_date: (this.countingDate)
              ? moment(this.countingDate).format("YYYY-MM-DD")
              : null,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    handleCancel(id) {
      this.cancelID = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to cancel this data",
        accept: this.cancel,
      });
    },
    cancel() {
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/batch-ed/cancel/" + this.cancelID)
        .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: "The data was successfully canceled",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleExecute(id) {
      this.executeID = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to execute this data",
        accept: this.execute,
      });
    },
    execute() {
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/batch-ed/execute-v3/" + this.executeID)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully approved",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(id) {
      this.$router.push({
        name: "batch-ed-edit",
        query: { id: id, action: "Edit" },
      });
    },
    handleDetail(id) {
      this.$router.push({
        name: "batch-ed-view",
        query: { id: id, action: "View" },
      });
    },
    getPermission() {
      this.$vs.loading();
      this.$http
        .get("/api/account/v1/permission", {
          params: {
            module: "batch-ed",
            source: "WMS"
          }
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code === 200) {
            this.permission = resp.data;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    }
  },
  mounted() {
  },
  watch: {
    draw() {
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    hasPermission() {
      return (guardName) => {
        return this.permission.some((perm) => perm.GuardName === guardName);
      };
    }
  },
};
</script>
